import styled from "styled-components";

const Container = styled.div`
    height: 100%;
    width: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Config = styled.div`
    width: 80%;
    padding: 10px 0;
    margin: 5% 0;
    display: flex; 
    justify-content: space-between;
    align-items: center;

    font-size: 1.1em;

    border: 1px solid #F99FC9;
    border-radius: 100px;
`

const Item = styled.div`
    transition: 0.3s ease all;

    width: 50%;
    height: calc(100% + 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    // font-weight: ${props => props.active ? 'bold' : 'normal'};

    color: ${props => props.active ? '#753BBD' : '#82899C'};

    border-radius: 100px;
    background-color: ${props => props.active ? '#F99FC9' : 'none'};`

const ListContainer = styled.div`
    height: 90%;
    width: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ListItem = styled.div`
    width: 80%;
    max-width: 500px;
    height: 18%;
    min-height: 60px;
    display: flex;

    align-items: center;
    justify-content: space-between;

    font-weight: bold;
    color: grey;
`

const ListPosition = styled.div`
    width: 20%;
    display: flex;
    justify-content: space-between;
`

const ListInformation = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

export {
    Container,
    ListContainer,
    Config, Item,
    ListItem,
    ListPosition,
    ListInformation
}