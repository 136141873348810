import React , { useState, useEffect } from 'react';

import {
	Routes,
	Route,
	useLocation,
} from "react-router-dom";

import useStore from './store/store';

import Home from './components/home/Home';
import Nav from './components/nav/Nav'
import Head from './components/head/Head';
import Auth from './assets/components/auth/Auth';

import client from './assets/service/client';
import { Container } from './styles';
import Loader from './assets/components/loader/Loader';
import User from './components/user/User';
import Event from './components/event/Event';
import CreateEventForm from './components/create/createEvent/CreateEventForm';
import Analytics from './components/event/analytics/Analytics';
import List from './components/event/list/List';
import Control from './components/event/control/Control';
import Transfer from './components/event/transfer/Transfer';
import Edit from './components/event/edit/Edit';
import CreateTicketSpecForm from './components/event/control/ticketSpecForm/CreateTicketSpecForm';
import UpdateTicketSpecForm from './components/event/control/ticketSpecForm/UpdateTicketSepcForm';
import Promo from './components/event/promo/Promo';
import CreatePromoForm from './components/event/promo/promoForm/CreatePromoForm';
import UpdatePromoForm from './components/event/promo/promoForm/UpdatePromoForm';
import CreateOrganizationForm from './components/create/createOrganization/CreateOrganizationForm'

const App = () => {
	const location = useLocation();
	const [loading, setLoading] = useState(true);
	const setUser = useStore(state => state.setUser);
	const dropUser = useStore(state => state.dropUser);
    const user = useStore(state => state.user);

	const ping = () => {
		console.log('ping')
		
		client.get('auth/ping/admin')
		.then(res => {
			console.log('logged in')
			setUser(res.data.admin);
		})
		.catch(err => {
			dropUser();
		})
		.finally(onFinally => setLoading(false))
	}

	useEffect(() => {
		ping();
	}, []);

	useEffect(() => {
		if (process.env.REACT_APP_PAGE_TITLE) document.title = process.env.REACT_APP_PAGE_TITLE
  }, []);

	return (
		<>
			<Head />
			{user && <Nav pathName={location.pathname}/>}
			<Container>
			{
			loading ?
				<Loader />
			: !user ?
			<Routes>
				<Route exact path="/create-organization" element={<CreateOrganizationForm />} />
				<Route path="/*" element={<Auth />} />
			</Routes>
				
			:
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route exact path="/user" element={<User />} />
					<Route exact path="/events" element={<Home />} />
					<Route exact path="/events/:id" element={<Event />} />
					<Route exact path="/events/:id/analytics" element={<Analytics />} />
					<Route exact path="/events/:id/list" element={<List />} />
					<Route exact path="/events/:id/control" element={<Control />} />
					<Route exact path="/events/:id/control/new" element={<CreateTicketSpecForm />} />
					<Route exact path="/events/:id/control/update/:specId" element={<UpdateTicketSpecForm />} />

					<Route exact path="/events/:id/promo" element={<Promo />} />
					<Route exact path="/events/:id/promo/new" element={<CreatePromoForm />} />
					<Route exact path="/events/:eventId/promo/update/:promoId" element={<UpdatePromoForm />} />

					<Route exact path="/events/:id/transfer" element={<Transfer />} />
					<Route exact path="/events/:id/edit" element={<Edit />} />
					<Route exact path="/new" element={<CreateEventForm />} />
					<Route path="/*" element={<>404</>} />
				</Routes>
			}
			</Container>
		</>
	);
}

export default App;
