import client from "../../assets/service/client";
import useStore from "../../store/store";

const User = () => {
    const dropUser = useStore(state => state.dropUser);
    const user = useStore(state => state.user);

    const logout = async () => {
        await client.post('/auth/logout');
        dropUser();
    }

    return (
    <>
        <>{user.email}</>
        <br/>
        <br/>
        <div onClick={logout}>log out</div>
    </>
    )
}

export default User;