import styled, { keyframes, css } from 'styled-components';

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
`

const ItemWrapper = styled.div`
    width: 85%;
    margin: 10px 0;
`

const Label = styled.label`
    width: 100%;
    font-weight: 300;
`

const FormItem = styled.input`
    width: calc(100% - 20px);
    font-size: 1.5em;
    height: 1.75em;
    border-radius: 5px;
    padding: 0 10px;
    border: 1px solid #470A68;
    background-color: rgba(255, 255, 255, 0.1);   

    font-family: IdealSans;
    margin-top: 5px;
    font-weight: 300;
`

const shakeAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(-10px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
`;

const Button = styled.button`
    bottom: 10px;

    height: 8%;
    min-height: 50px;
    width: 80%;
    align-self: center;
    
    text-align: center;
    cursor: pointer;

    font-family: IdealSans;
    font-size: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    border-radius: 15px;
    font-weight: bold;
    color: white;
    background-color: #470A68;
    text-decoration: none;

    box-shadow: 0 0 10px rgba(71, 10, 104, 0.5);

    margin: 35px 0 25px 0;

    ${({ shake }) =>
    shake &&
    css`
      animation: ${shakeAnimation} 0.5s;
    `};
`


export {
  Container,
  Label,
  FormItem,
  ItemWrapper,
  Button
}