import { useState } from 'react';
import client from "../../../assets/service/client";

import useStore from '../../../store/store';

import { useNavigate } from "react-router-dom";

import Loader from "../../../assets/components/loader/Loader"

import { 
  Container,
  Label,
  FormItem,
  ItemWrapper,
  Button
} from './styles';

import Popup from "../../popup/Popup.js";


const CreateOrganizationForm = () => {

  const navigate = useNavigate();

  const setUser = useStore(state => state.setUser);
	const dropUser = useStore(state => state.dropUser);

  const [isLoading, setIsLoading] = useState(false);
  const [isShaking, setIsShaking] = useState(false);
  const [isError, setIsError] = useState(false);

  const [organizationName, setOrganizationName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = async () => {

    if (organizationName === "" || email === "" || password === "") {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 500);
      return;
    }

    setIsLoading(true);
    await client.post("organizer/new", {
      name: organizationName,
      description: organizationName.toLowerCase().replace(/\s/g, "-"),
      email: `${organizationName.toLowerCase().replace(/\s/g, "-")}@gandaya.io`
    })
    .then(res => {
      const organizerId = res.data.organizerId
      client.post("organizer/new/user", {
        organizerId: organizerId,
        adminPermissionId: 1,
        email: email,
        password: password
      })
      .then(res => {
        login()
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
        setIsError(true)
      })
    })
    .catch(err => {
      console.log(err)
      setIsLoading(false)
      setIsError(true)
    })
  }

  const login = async () =>{
    await client.post('auth/login/admin', {
      email: email,
      password: password
    })
    .then(res => {
      client.get('auth/ping/admin')
        .then(res => {
          setUser(res.data.admin);
          navigate("/events")
        })
        .catch(err => {
          console.log(err)
          dropUser();
          navigate("/")
        })
    })
    .catch(err => {
      console.log(err)
      navigate("/")
    })
  }

  return (
    isLoading ? <Loader/> :
    <Container>
      <ItemWrapper>
        <Label>Nome da empresa</Label>
        <FormItem type="text" value={organizationName} onChange={(e) => setOrganizationName(e.target.value)}/>
      </ItemWrapper>
      <ItemWrapper>
        <Label>E-mail</Label>
        <FormItem type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>
      </ItemWrapper>
      <ItemWrapper>
        <Label>Senha</Label>
        <FormItem type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
      </ItemWrapper>

      <Button onClick={onSubmit} shake={isShaking} >
        Criar organizador
      </Button>

      {isError && <Popup setIsError={setIsError} text="Ocorreu um erro. Reveja se todos os itens estão definidos corretamente." top="40vh"/>}
    </Container>
  )
}

export default CreateOrganizationForm;