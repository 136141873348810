import { useQuery } from 'react-query'
import React, { useState, useEffect, useRef } from "react";

import { useNavigate, useParams } from "react-router-dom";
import client from "../../../assets/service/client";
import Loader from '../../../assets/components/loader/Loader';
import { 
    Container,
    CoverImage, 
    Title,
    Description,
    Instagram,
    Spotify,
    Label,
    ItemWrapper,
    Button,
} from './styles';


const Edit = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [instagram, setInstagram] = useState("");
    const [spotify, setSpotify] = useState("");
    const [imageKey, setImageKey] = useState("");
    const [imageId, setImageId] = useState("");

    const compressAndSendImage = async (file) => {
        const MAX_WIDTH = 600;
        const MAX_HEIGHT = 600;
        const MIME_TYPE = "image/jpeg";
        const QUALITY = 1;

        function calculateSize(img, maxWidth, maxHeight) {
            let width = img.width;
            let height = img.height;
          
            // calculate the width and height, constraining the proportions
            if (width > height) {
                if (width > maxWidth) {
                    height = Math.round((height * maxWidth) / width);
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width = Math.round((width * maxHeight) / height);
                    height = maxHeight;
                }
            }
            return [width, height];
        }

        const blobURL = URL.createObjectURL(file);
        const img = new Image();
        img.src = blobURL;
        img.onerror = function () {
            URL.revokeObjectURL(this.src);
            console.log("Cannot load image");
        };
        
        img.onload = function () {
            URL.revokeObjectURL(this.src);
            const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
            const canvas = document.createElement("canvas");
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            canvas.toBlob(
            async (blob) => {
                await sendImage(blob)
            },
            MIME_TYPE,
            QUALITY
            );
        }
    }

    const openFileExplorer = () => {
        inputFile.current.click();
    }

    const sendImage = async (file) => {
        const formData = new FormData();
        formData.append("image", file);

        let uploadImageResponse = await client({
            method: 'POST',
            url: `image/upload`, 
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })

        setImageKey(uploadImageResponse.data.image.key)
        setImageId(uploadImageResponse.data.image.imageId)

        return uploadImageResponse;
    }

    const setImage = async (event) => {
        event.stopPropagation();
        event.preventDefault();

        var file = event.target.files[0];
        await compressAndSendImage(file);
    }

    const onSubmit = async () => {
        await client.post("event/edit", {
            id,
            name: title,
            description,
            instagram, spotify,
            backgroundArtImageId: imageId
        })

        navigate(-1);
    }

    const getEventSpecs = async () => {
        return await client.get(`event/${id}`)
        .then(res => {   
            let { event } = res.data;
            event.name && setTitle(event.name)
            event.description && setDescription(event.description)
            event.instagram && setInstagram(event.instagram)
            event.spotify && setSpotify(event.spotify)
            setImageKey(event.backgroundArtImage.key)        
            setImageId(event.backgroundArtImage.id)        
            return res.data.event;
        })
        .catch(err => err)
    }

    const { isLoading, isError, data: specs, error, refetch: refetchEventSpecs } = useQuery(['eventSpec', id], getEventSpecs, { keepPreviousData: false });

    const inputFile = useRef(null);

    return (
        isLoading ?
        <Loader /> :
        specs &&
        <Container>
            <input type='file' id='file' ref={inputFile} onChange={setImage} style={{display: 'none'}}/>
            <CoverImage onClick={openFileExplorer} img={`${process.env.REACT_APP_S3_URL}/${imageKey}`} />
            <ItemWrapper>
            <Label>Título</Label>
            <Title value={title} onChange={(event) => setTitle(event.target.value)} />
            </ItemWrapper>
            <ItemWrapper>
            <Label>Descrição</Label>
            <Description value={description} onChange={(event) => setDescription(event.target.value)} />
            </ItemWrapper>
            <ItemWrapper>
            <Label>Instagram</Label>
            <Instagram value={instagram} onChange={(event) => setInstagram(event.target.value)} />
            </ItemWrapper>
            <ItemWrapper>
            <Label>Spotify</Label>
            <Spotify value={spotify} onChange={(event) => setSpotify(event.target.value)} />
            </ItemWrapper>
            <div style={{width: "100%", minHeight: "50px", height: "8%", margin: "10px 0"}} />
            <Button onClick={onSubmit}>Salvar Alterações</Button>
        </Container>
    )
}

export default Edit