import { useState } from "react";
import { useLocation } from 'react-router-dom'
import { useNavigate, useParams } from "react-router-dom";
import { ItemWrapper, Label, Input, Container, Button } from "./styles"
import client from "../../../../assets/service/client";
import { useQuery } from "react-query";

const UpdateTicketSpecForm = (props) => {
    const location = useLocation()
    const { id, specId } = useParams();

    const { state: { ticket } } = location;
    const [description, setDescription] = useState(ticket.description);
    const [price, setPrice] = useState(ticket.price);
    // const [promo, setPromo] = useState(ticket.promo);
    const [numberOfAvailableTickets, setnumberOfAvailableTickets] = useState(ticket.numberOfAvailableTickets);
    const [orderLimit, setOrderLimit] = useState(ticket.orderLimit);
    const [followerId, setFollowerId] = useState(ticket.followerId)
    const navigate = useNavigate();

    const onSubmit = async () => {
        await client.post("ticket/spec/update", {
            specId,
            description,
            // price,
            // promo,
            numberOfAvailableTickets,
            orderLimit,
            price,
            followerId: followerId != 0 ? followerId : null
        })
        navigate(-1)
    }

    const getTicketSpecsByEventId = async () => {
        return await client.get(`/event/${id}/ticketSpecs`)
        .then(res => {
            return res.data.ticketSpecs;
        })
        .catch(err => err)
    }

    const { data: ticketSpecs } = useQuery('ticketSpecsByEventId', getTicketSpecsByEventId);

    return (
        <Container>
            <ItemWrapper>
                <Label>Descrição*</Label>
                <Input value={description} onChange={(event) => setDescription(event.target.value)} />
            </ItemWrapper>
            <ItemWrapper>
                <Label>Preço*</Label>
                {/* <Input type="number" onChange={(event) => setPrice(event.target.value)} value={price} /> */}
                <Input type="number" disabled readOnly value={price} />
            </ItemWrapper>
            <ItemWrapper>
                <Label>Número de ingressos disponíveis</Label>
                <Input value={numberOfAvailableTickets ? numberOfAvailableTickets : ""} onChange={(event) => setnumberOfAvailableTickets(event.target.value)} />
            </ItemWrapper>
            <ItemWrapper>
                <Label>Limite por comprador</Label>
                <Input value={orderLimit ? orderLimit : ""} onChange={(event) => setOrderLimit(event.target.value)} />
            </ItemWrapper>
            <ItemWrapper>
                <Label>Seguinte</Label>
                {
                ticketSpecs && 
                <div style={{marginTop: "10px"}}>
                <select value={followerId} onChange={(event) => setFollowerId(event.target.value)}>
                    <option value={0}> - </option>
                    {ticketSpecs.map(t => <option key={t.id} value={t.id}>{t.description}</option>)}
                </select>
                </div>
                }
            </ItemWrapper>
            <Button onClick={onSubmit}>Salvar</Button>
        </Container>
        )
}

export default UpdateTicketSpecForm