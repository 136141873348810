import React , { useState, useEffect } from 'react';

import useStore from '../../../store/store';
import client from '../../service/client';

import Login from './Login';
import Register from './Register';

import Loader from '../loader/Loader';
import { Container, Modal, Logo, Form } from './styles';

const Auth = (props) => {
	const [loading, setLoading] = useState(false)

	const setUser = useStore(state => state.setUser);
	const dropUser = useStore(state => state.dropUser);

	useEffect(() => {
        return () => {
            setLoading(false);
        }
    }, [])

	const ping = () => {
		console.log('ping');
		
		client.get('auth/ping/admin')
		.then(res => {
			setUser(res.data.admin);
		})
		.catch(err => {
			dropUser();
			setLoading(false);
		})
	}

    return (
		loading ?
			<Loader />
		:
		<Container>
			<Modal>
				<Logo />
				<Form>
					<Login ping={ping} />
				</Form>
			</Modal>
		</Container>
    )
}

export default Auth;