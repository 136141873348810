import client from "../../../assets/service/client";
import { useQuery, useMutation } from "react-query";
import { Link, useParams } from "react-router-dom";

import {
    Container, 
    ListWrapper,
    Wrapper,
    Informative,
    PromoInfo,
    Description,
    Price,
    Button,
    Config,
    Item,
    ButtonWrapper,
    ConfirmationWrapper,
    ButtonLink,
    Search,
} from './styles'

import { useState } from "react";
import Loader from "../../../assets/components/loader/Loader";

import { FaRegCopy } from 'react-icons/fa'

const Promo = (props) => {
    const { id } = useParams();
    const [activePromos, setActivePromos] = useState([])

    const [filter, setFilter] = useState('')

    const getEventSpecs = async () => {
        return await client.get(`event/${id}`)
        .then(res => {            
            return res.data.event;
        })
    }

    const getPromosByEventId = async () => {
        return await client.get(`/promo/${id}`)
        .then(res => {
            let currentActivePromos = [];
            for (let p of res.data.promos) {
                if (p.active) currentActivePromos.push(parseInt(p.id))
            }
            setActivePromos(currentActivePromos);
            return res.data.promos;
        })
    }

    const setBatches = async ({eventId, promoIds}) => {
        return await client.post(`/promo/active`, {
            eventId,
            promoIds
        })
        .then(res => {
            return res.data;
        })
    }

    const checked = (event) => {
        const promoId = parseInt(event.target.value);
        const { checked } = event.target;
        if (checked) {
            setActivePromos([...activePromos, promoId])
        } else {
            setActivePromos(activePromos.filter(pid => pid != promoId))
        }
    }

    const copy = (text, domain, alias) => {
        let textarea = document.createElement("textarea");
        textarea.textContent = `${domain || "https://gandaya.io"}/events/${alias}/tickets?promo=${text}`;
        textarea.style.position = "fixed";
        textarea.style.width = '2em';
        textarea.style.height = '2em';
        textarea.style.padding = 0;
        textarea.style.border = 'none';
        textarea.style.outline = 'none';
        textarea.style.boxShadow = 'none';
        textarea.style.background = 'transparent';
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();

        document.execCommand("copy");
        document.body.removeChild(textarea);
    }

    const { data: promos } = useQuery('promosByEventId', getPromosByEventId);
    const {isIdle, isLoading, isError, isSuccess, mutate} = useMutation(setBatches);
    const { isLoading: isEventLoading, isError: isEventError, data: event, error } = useQuery(['eventSpec', id], getEventSpecs, { keepPreviousData: false });


    return (
        <Container>
            <Config>
                <Item active={true} onClick={ () => {} }>Promos</Item>
            </Config>
            <Search name="filter" placeholder="Filtro" value={filter} onChange={(event) => setFilter(event.target.value.toLowerCase())}/>
            <ListWrapper>
                {
                    promos && promos.filter(p => p.code.toLowerCase().includes(filter)).map(p => {
                        return (
                            <Wrapper key={p.id}>
                                <Informative>
                                    <PromoInfo to={`/events/${id}/promo/update/${p.id}`} state={{ promo: p}}>
                                        <Description>{p.code}</Description>
                                    </PromoInfo>
                                    <Description>{p.usage}/{p.limit ? p.limit : "ilimitado"}</Description>
                                    <div>
                                    <FaRegCopy style={{marginRight: "15px"}} onClick={() => { if (event) copy(p.code, event.domain, event.alias) } } />
                                    <input type="checkbox" checked={activePromos.includes(parseInt(p.id))} value={p.id} onChange={(event) => checked(event)}></input>
                                    </div>
                                </Informative>
                            </Wrapper>
                        )
                    })
                }
            </ListWrapper>
            {
            isIdle ? 
            <ButtonWrapper>
                <ButtonLink to={`/events/${id}/promo/new`}><span style={{fontSize: '2em', fontWeight: "bold"}}>+</span></ButtonLink>
                <Button onClick={() => mutate({eventId: id, promoIds: activePromos})}>Salvar</Button>
            </ButtonWrapper> :
            isLoading ? <Loader scale={0.4} /> :
            isError ? <>algo deu errado</> :
            isSuccess && 
            <ConfirmationWrapper>
                <span>Códigos atualizados com sucesso</span> 
                <Link to={`/events/${id}`}>Voltar</Link>
            </ConfirmationWrapper>
            }
        </Container>
    )

}

export default Promo