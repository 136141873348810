import { useRef, useState } from "react";
import Loader from "../../../assets/components/loader/Loader"
import DatePicker from "react-datepicker";
import Switch from "react-switch";
import client from "../../../assets/service/client";
import useStore from "../../../store/store";
import { useNavigate } from "react-router-dom";

import Popup from "../../popup/Popup.js";

import { AiOutlineCloudUpload } from "react-icons/ai"

import "react-datepicker/dist/react-datepicker.css";
import { 
    Container,
    CoverImage, 
    FormItem,
    Description,
    Label,
    ItemWrapper,
    DoubleItemWrapper,
    Wrapper,
    Button,
    DatePickerWrapperStyles,
    ImageWrapper
} from './styles';

import ptBR from 'date-fns/locale/pt-BR';


const CreateEventForm = () => {

    const navigate = useNavigate();

    const user = useStore(state => state.user);
    const { organizer } = user

    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [imageKey, setImageKey] = useState("");
    const [imageId, setImageId] = useState("");
    const [eventDate, setEventDate] = useState(null);
    const [isError, setIsError] = useState(false);
    const [privateEvent, setPrivateEvent] = useState(false);

    const compressAndSendImage = async (file) => {
        const MAX_WIDTH = 600;
        const MAX_HEIGHT = 600;
        const MIME_TYPE = "image/jpeg";
        const QUALITY = 1;

        function calculateSize(img, maxWidth, maxHeight) {
            let width = img.width;
            let height = img.height;
          
            // calculate the width and height, constraining the proportions
            if (width > height) {
                if (width > maxWidth) {
                    height = Math.round((height * maxWidth) / width);
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width = Math.round((width * maxHeight) / height);
                    height = maxHeight;
                }
            }
            return [width, height];
        }

        const blobURL = URL.createObjectURL(file);
        const img = new Image();
        img.src = blobURL;
        img.onerror = function () {
            URL.revokeObjectURL(this.src);
            console.log("Cannot load image");
        };
        
        img.onload = function () {
            URL.revokeObjectURL(this.src);
            const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
            const canvas = document.createElement("canvas");
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            canvas.toBlob(
            async (blob) => {
                await sendImage(blob)
            },
            MIME_TYPE,
            QUALITY
            );
        }
    }

    const openFileExplorer = () => {
        inputFile.current.click();
    }

    const sendImage = async (file) => {
        const formData = new FormData();
        formData.append("image", file);

        let uploadImageResponse = await client({
            method: 'POST',
            url: `image/upload`, 
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })

        setImageKey(uploadImageResponse.data.image.key)
        setImageId(uploadImageResponse.data.image.imageId)

        return uploadImageResponse;
    }

    const setImage = async (event) => {
        event.stopPropagation();
        event.preventDefault();

        var file = event.target.files[0];
        await compressAndSendImage(file);
    }

    const onSubmit = async () => {
        setIsLoading(true);
        await client.post("event/new", {
            name: title,
            numberOfAvailableTickets: 5000,
            alias: title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s/g, "-"),
            date: eventDate?.toISOString().split('T')[0].replaceAll('-', '/'),
            backgroundArtImageId: imageId,
            description: description.length > 0 ? description : ".",
            organizerId: organizer.id,
            private: privateEvent,
            batches: []
        })
        .then(res => {
            setIsLoading(false)
            navigate(`/events/${res.data.eventId}`)
        })
        .catch(err => {
            console.log(err)
            setIsLoading(false)
            setIsError(true)
        })
    }

    const inputFile = useRef(null);

    return  (isLoading ? <Loader /> : <>
    <Container>
        <input type='file' id='file' ref={inputFile} onChange={setImage} style={{display: 'none'}}/>
        <ItemWrapper>
            <Label>Imagem</Label>
            {imageKey ? (
            <CoverImage onClick={openFileExplorer} img={`${process.env.REACT_APP_S3_URL}/${imageKey}`} />) : (<ImageWrapper onClick={openFileExplorer}>
                <AiOutlineCloudUpload size={50} color="#470A68"/>
             </ImageWrapper>
            )}
            
        </ItemWrapper>
        <ItemWrapper>
            <Label>Título</Label>
            <FormItem value={title} onChange={(event) => setTitle(event.target.value)} />
        </ItemWrapper>
        <DoubleItemWrapper>
            <Wrapper>
                <Label>Data</Label>
                <DatePicker
                    closeOnScroll={true}
                    selected={eventDate}
                    onChange={(date) => setEventDate(date)}
                    wrapperClassName='date_picker full-width'
                    dateFormat="dd/MM/yyyy"
                    locale={ptBR}
                    width="70%"
                />
                <DatePickerWrapperStyles />
            </Wrapper>
            <Wrapper>
                <Label>Privado</Label>
                <div style={{height: "100%", display: "flex", alignItems:"center"}}>
                    <Switch
                        checked={privateEvent}
                        onChange={() => setPrivateEvent(!privateEvent)}
                        width={70}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onColor="#F68DBD"
                        offColor="#fff"
                        onHandleColor="#470A68"
                        offHandleColor="#ddd"
                    />
                </div>
            </Wrapper>
        </DoubleItemWrapper>
        <ItemWrapper>
            <Label>Descrição</Label>
            <Description value={description} onChange={(event) => setDescription(event.target.value)} />
        </ItemWrapper>
        
        <Button onClick={onSubmit}>Criar Evento</Button>

        {isError && <Popup setIsError={setIsError} text="Ocorreu um erro. Reveja se todos os itens estão definidos corretamente." top="40vh"/>}
    </Container>
    </>)
}

export default CreateEventForm