import { useState, useEffect } from 'react';
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Loader from "../../assets/components/loader/Loader";
import client from "../../assets/service/client";
import { Container, TopContainer, RevenueWrapper, BottomContainer, Selector, SelectorWrapper, Button, SelectorButton } from "./styles";

import { AiOutlineOrderedList, AiFillControl, AiOutlineLink } from "react-icons/ai"
import { MdOutlineAnalytics } from "react-icons/md"
import { BsPencilSquare } from "react-icons/bs"
import { RiCoupon2Line } from "react-icons/ri"

import useStore from "../../store/store";

import Popup from '../popup/Popup'

const Event = () => {
    const { id } = useParams();

    const user = useStore(state => state.user);
    const [eventLink, setEventLink] = useState("")
    const [showPopup, setShowPopup] = useState(false)

    const permissions = user.adminPermission

    const getPaymentsByEventId = async () => {
        return await client.get(`/event/${id}/payments`)
        .then(res => {
            return res.data.payments;
        })
        .catch(err => err)
    }

    const downloadPaymentInfo = (data) => {
        let json_parse = data.map(d => {
            return {"name": d.user, "amount": d.amount, "date": d.createdAt }
        })

        let headers = ["name,amount,date"]
        let compile = json_parse.reduce((acc, p) => {
            let { name, amount, date } = p
            acc.push([name, amount, date].join(','))
            return acc
        }, [])

        const blob = new Blob([ [ ...headers, ...compile ].join('\n') ], { type: "text/csv" });

        const a = document.createElement('a')
        a.download = 'payments.csv'
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const { isLoading, isError, data: payments } = useQuery('paymentsByEventId', getPaymentsByEventId);

    useEffect(() => {
        client.get(`/event/${id}`)
        .then(res => {
            const clientEventUrl = `${process.env.REACT_APP_CLIENT_URL}/events/${res.data.event.alias}`
            setEventLink(clientEventUrl)
        })
        .catch(err => {
            console.log(err)
        })
    }, [])

    function copyToClipboard() {
        navigator.clipboard.writeText(eventLink)
        setShowPopup(true)
    }

    return (
        isLoading ?
        <Loader />
        : isError ?
        <>something went wrong</>
        : payments &&
        <Container>
            <TopContainer>
                <RevenueWrapper>
                <span>Faturamento Total</span>
                {
                permissions.revenue ? 
                <span style={{fontSize: "2em", fontWeight: "bold"}} onClick={() => downloadPaymentInfo(payments)}>
                    R$ { payments && payments.filter(p => !p.cancelled).reduce((acc, curr) => acc + parseFloat(curr.amount), 0).toFixed(2)}
                </span>
                :
                <span style={{fontSize: "2em", fontWeight: "bold"}} >R$ 🙈🙈</span>
                }
                </RevenueWrapper>
            </TopContainer>
            <BottomContainer>
                {
                permissions.list && <SelectorWrapper>
                    <Selector to={`/events/${id}/list`}> <AiOutlineOrderedList style={{fontSize: "1.5em"}}/> </Selector>
                    <>Lista</>
                </SelectorWrapper>
                } {
                permissions.analytics && <SelectorWrapper>
                    <Selector to={`/events/${id}/analytics`}> <MdOutlineAnalytics style={{fontSize: "1.5em"}}/> </Selector>
                    <>Analíticos</>
                </SelectorWrapper>
                } {
                permissions.control && <SelectorWrapper>
                    <Selector to={`/events/${id}/control`}> <AiFillControl style={{fontSize: "1.5em"}}/> </Selector>
                    <>Lotes</>
                </SelectorWrapper>
                } {
                permissions.edit && <SelectorWrapper>
                    <Selector to={`/events/${id}/edit`}>  <BsPencilSquare style={{fontSize: "1.3em"}}/> </Selector>
                    <>Editar</>
                </SelectorWrapper>
                }
                {
                permissions.control && <SelectorWrapper>
                    <Selector to={`/events/${id}/promo`}>  <RiCoupon2Line style={{fontSize: "1.3em"}}/> </Selector>
                    <>Promo</>
                </SelectorWrapper>
                }
                    <SelectorWrapper>
                        <SelectorButton onClick={() => copyToClipboard()}>  
                            <AiOutlineLink style={{fontSize: "2em"}}/> 
                        </SelectorButton>
                        <>Copiar link</>
                    </SelectorWrapper>
                </BottomContainer>
                <> { permissions.grant && <Button to={`/events/${id}/transfer`}>Enviar Ingresso</Button> } </>
                {showPopup && <Popup setIsError={setShowPopup} text="Link copiado! Agora você pode compartilhar o link do seu evento com todo mundo." top="50vh"/>}
        </Container>
    )
}

export default Event