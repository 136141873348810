import { useState } from "react"
import client from "../../service/client"

import {
    Wrapper,
    Input,
    Button,
    ForgotPassword
} from './styles'

const Login = (props) => {
	const [inputField , setInputField] = useState({
        email: '',
        password: ''
    })

    const inputsHandler = (e) =>{
        setInputField( {...inputField, [e.target.name]: e.target.value} )
    }

	const submitButton = () =>{
        client.post('auth/login/admin', {
			email: inputField.email,
			password: inputField.password
		})
		.then(res => props.ping())
        .catch(err => props.ping())
    }

	return (
        <Wrapper>
            <Input 
                type="text" 
                name="email" 
                onChange={inputsHandler} 
                placeholder="E-mail" 
                value={inputField.email}
            />

            <Input 
                type="password" 
                name="password" 
                placeholder="Senha" 
                onChange={inputsHandler} 
                value={inputField.password}
            />

            <ForgotPassword
                href="https://wa.me/+5511995093002"
            >Esqueceu a senha?</ForgotPassword>
            

            <Button onClick={submitButton}>LOG IN</Button>
        </Wrapper>
	)
}

export default Login