import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    overflow-y: scroll
`

const TopContainer = styled.div`
    margin-top:0;
    width: 90%;
    height: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const RevenueWrapper = styled.div`
    width: 100%;
    height: 100px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`

const BottomContainer = styled.div`
    height: 80%;
    max-height: 400px;
    width: 80%;
    max-width: 300px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    background-color: #fff;

    // border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

const SelectorWrapper = styled.div`
    height: 80%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: auto;
`

const Selector = styled(Link)`
    height: 75px;
    width: 75px;
    background-color: rgb(35, 48, 77);
    border-radius: calc(75px / 2);

    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    box-shadow: 0 0 0 3px #e78267;
`

const SelectorButton = styled.button`
    height: 75px;
    width: 75px;
    background-color: rgb(35, 48, 77);
    border-radius: calc(75px / 2);
    border: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    box-shadow: 0 0 0 3px #e78267;
`

const Button = styled(Link)`
    height: 8%;
    min-height: 50px;
    width: 80%;
    align-self: center;
    
    text-align: center;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 15px;
    font-weight: bold;
    color: white;
    background-color: #470A68;
    text-decoration: none;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

export {
    Container, 
    TopContainer,
    RevenueWrapper,
    BottomContainer,
    SelectorWrapper,
    Selector,
    SelectorButton,
    Button
}