import styled from 'styled-components';

import logo from '../../images/ganda_layers.svg'

const Container = styled.div`
    position: fixed;    
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Modal = styled.div`
    width: 80%;
    height: 70%;
    min-height: 250px;
    // border: 1px solid rgba(0, 0, 0, 0.1);
    // border-radius: 5px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`

const Logo = styled.div`
    height: calc(162px * 0.7);
    width: calc(273px * 0.7);

    background-image: url(${logo});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`

const Form = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 40%;
    width: 80%;
    max-height: 140px;
`

const Wrapper = styled.div`
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`

const Input = styled.input`
    width: 80%;
    height: 24px;

    border: 0;
    border-radius: 5px;
    margin: 8px 0;
    padding: 5px 10px;

    font-family: IdealSans;
    font-weight: 300;
`

const Button = styled.button`
    width: 40%;

    border: 0;
    border-radius: 5px;
    background-color: #470A68;

    padding: 8px 0;
    margin-top: 20px;

    color: #fff;
    font-family: IdealSans;
`

const ForgotPassword = styled.a`
    font-size: 0.8rem;
    margin-top: 10px;
    color: #470A68;
    text-decoration: none;
`

export {
    Container,
    Modal,
    Logo,
    Form,
    Wrapper,
    Input,
    Button,
    ForgotPassword
}