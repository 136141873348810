import styled from 'styled-components';

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
`

const ItemWrapper = styled.div`
    width: 85%;
    margin: 10px 0;
`

const Label = styled.label`
    width: 100%;
    font-weight: 300;
`

const Input = styled.input`
    width: calc(100% - 20px);
    font-size: 1.5em;
    height: 1.75em;
    border-radius: 5px;
    padding: 0 10px;
    border: 1px solid #470A68;
    background-color: rgba(255, 255, 255, 0.1);   

    font-family: IdealSans;
    margin-top: 5px;
    font-weight: 300;
`

const Button = styled.div`
    position: absolute;
    bottom: 10px;

    height: 8%;
    min-height: 50px;
    width: 80%;
    align-self: center;
    
    text-align: center;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 15px;
    font-weight: bold;
    color: white;
    background-color: #470A68;
    text-decoration: none;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`

const Wrapper = styled.div`
    width: 80%;
    height: 20%;
    max-height: 100px;
    min-height: 70px;
    margin: 8px 0;

    display: flex; 
    align-items: center;
    justify-content: center;

    background-color: white;

    // border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-decoration: none;
`

const Informative = styled.div`
    width: 80%;
    height: 100%;

    display: flex; 
    align-items: center;
    justify-content: space-between;

    text-decoration: none;
`

const TicketInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 75%;

    text-decoration: none;
`

const Description = styled.div`
    color: gray;
    font-size: 0.8em;
`

const Price = styled.div`
    text-align: center;
    color: #753BBD;
    font-weight: bold;
`

const ListWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
`

export {
    Container,
    ItemWrapper,
    Label,
    Input,
    Button,

    Wrapper,
    Informative,
    TicketInfo,
    Description,
    Price,
    ListWrapper
}