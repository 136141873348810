import { Header, HeaderWrapper, HeadItem, Logo } from "./styles";

const Head = () => {
 return (
    <HeaderWrapper>
        <Header>
            <HeadItem></HeadItem>
            <HeadItem><Logo /></HeadItem>
            <HeadItem></HeadItem>
        </Header>
    </HeaderWrapper>
 )
}

export default Head;