import styled from "styled-components";

const DownloadIcon = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: rgb(35, 48, 77);
    height: 50px;
    width: 50px;
    border-radius: calc(50px / 2);
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
`

const AnalyticsContainer = styled.div`
    height: 100%;
    width: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    min-height: 40%;
    min-width: 80%;
`

export {
    AnalyticsContainer,
    ChartContainer,
    DownloadIcon
}