import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(5px);
`

const PopupContainer = styled.div`
  position: relative;
  top: ${props => props.top};
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-width: 300px;
  min-height: 100px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 25px 10px;
`

const Label = styled.label`
  max-width: 80%;
  font-weight: 300;
  text-align: center;
`

const Button = styled.button`
  width: 60%;
  cursor: pointer;
  text-align: center;

  border-radius: 15px;
  border: 1px solid #470A68;

  font-weight: bold;
  background-color: #fff;
  text-decoration: none;

  padding: 10px;
  margin-top: 20px;
`

export {
  Container,
  PopupContainer,
  Label,
  Button
}