import { useEffect, useRef, useState } from "react";

import { 
    Container,
    PopupContainer,
    Label,
    Button
} from './styles';


const Popup = ({setIsError, text, top}) => {
    
    const closePopup = () => {
        setIsError(false)
    }

    return  (
      <Container>
          <PopupContainer top={top}>
            <Label>{text}</Label>
            <Button onClick={closePopup}>OK</Button>
          </PopupContainer>
      </Container>
    )
}

export default Popup