import { useQuery } from "react-query";
import client from "../../assets/service/client";
import useStore from "../../store/store";

import {
    ListContainer,
    Wrapper,
    Image,
    DescriptionWrapper,
    Description,
    Title,
    Date,
    NewEventIcon,
    NoEvent
} from './styles'

import { BsCalendarEvent, BsFillPlusCircleFill } from 'react-icons/bs';
import { IoMdCreate } from 'react-icons/io';

import Loader from '../../assets/components/loader/Loader'

const Home = () => {
    const user = useStore(state => state.user);
    const { organizer } = user

    const getEventsByOrganizer = async () => {
        return await client.get(`/event/organizer/${organizer.id}`)
        .then(res => {
            return res.data.events;
        })
        .catch(err => err)
    }

    const parseDate = (date) => {
        let d = new window.Date(date);
        return d.toLocaleString("pt-br", {
            month: "short", day:"numeric", weekday: "short"
        });
    }

    const { isLoading, isError, data: events, error } = useQuery('eventsByOrganizer', getEventsByOrganizer);
    return (
        <ListContainer>
            <NewEventIcon to="/new"> <BsFillPlusCircleFill style={{fontSize: "1.5em"}} /> </NewEventIcon>
            { 
                isLoading ? <Loader /> :
                isError ? <>ERROR</> :
                !events.length ? <NoEvent>Nenhum evento encontrado</NoEvent> :
                events.map(event => {
                    let link = event.alias.toString();
                    let imgKey = event.backgroundArtImage.key;
                    return (
                        <Wrapper to={`/events/${event.id}`} key={event.id}>
                            <Image img={`${process.env.REACT_APP_S3_URL}/${imgKey}`} />
                            <DescriptionWrapper>
                                <Description>
                                    <Title>{event.name}</Title>
                                    <Date><BsCalendarEvent style={{color: '#F04E98'}}/> &nbsp; {parseDate(event.date)}</Date>
                                </Description>
                            </DescriptionWrapper>
                        </Wrapper>
                    )
                }) 
            }
            </ListContainer>
    )
}

export default Home;