import styled from "styled-components";

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Modal = styled.div`
    width: 80%;
    height: 70%;
    // border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`

const TextWrapper = styled.div`
    width: 80%;
    height: 40%
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
`

const FormWrapper = styled.div`
    width: 80%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`

const Input = styled.textarea`
    width: 70%;
    height: 100px;

    // text-align: center;

    // border: 0;
    // outline: 0;

    // border-bottom: 1.5px solid #6A4F4F;

    font-family: rajdhani;
    // font-size: 1.3em;
    // font-weight: bold;

    background-color: rgba(255, 255, 255, 0);
`

export {
    Container, 
    Modal,
    TextWrapper,
    FormWrapper,
    Input
}