import styled, { createGlobalStyle } from 'styled-components';

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
`

const CoverImage = styled.div`
    width: 100%;
    height: 180px;
    margin: 10px 0;
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px 5px;
    background-color: #470A6815;
    border: 1px solid #470A68;
`

const ItemWrapper = styled.div`
    width: 85%;
    margin: 10px 0;
`

const DoubleItemWrapper = styled.div`
    width: 85%;
    margin: 10px 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    
    width: fit-content;

    height: 100%;
    max-width: 70%;
`

const Label = styled.label`
    width: 100%;
    font-weight: 300;
`

const FormItem = styled.input`
    width: calc(100% - 20px);
    font-size: 1.5em;
    height: 1.75em;
    border-radius: 5px;
    padding: 0 10px;
    border: 1px solid #470A68;
    background-color: rgba(255, 255, 255, 0.1);   

    font-family: IdealSans;
    margin-top: 5px;
    font-weight: 300;
`

const Description = styled.textarea`
    width: 100%;
    height: 30%;
    min-height: 200px;
    border: 1px solid #470A68;
    background-color: rgba(255, 255, 255, 0.1);   
    margin-top: 5px;
    border-radius: 5px;
    font-weight: 300;
`

const ImageWrapper = styled.div`
    width: 100%;
    height: 180px;
    margin: 10px 0;
    border-radius: 5px 5px;
    background-color: #470A6815;
    border: 1px solid #470A68;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Button = styled.div`
    /* position: absolute; */
    bottom: 10px;

    height: 8%;
    min-height: 50px;
    width: 80%;
    align-self: center;
    
    text-align: center;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 15px;
    font-weight: bold;
    color: white;
    background-color: #470A68;
    text-decoration: none;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    margin: 15px 0 25px 0;
`

const DatePickerWrapperStyles = createGlobalStyle`
    .date_picker.full-width {
        width: 100%;
    }
    /* .react-datepicker__input-container, */
    .react-datepicker__input-container input {
        width: calc(100% - 20px);
        font-size: 1.5em;
        height: 1.75em;
        border-radius: 5px;
        padding: 0 10px;
        border: 1px solid #470A68;
        background-color: rgba(255, 255, 255, 0.1);   

        font-family: IdealSans;
        margin-top: 5px;
        font-weight: 300;
    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
        border-bottom-color: #470A68;
    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
        border-bottom-color: 1px solid #470A68;
    }

    .react-datepicker__header {
        text-align: center;
        background-color: #470A6830;
        border-bottom: 0px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding-top: 8px;
        position: relative;
    }

    .react-datepicker__today-button {
        background: #470A68;
        border-top: 0px;
        cursor: pointer;
        text-align: center;
        font-weight: bold;
        padding: 5px 0;
        clear: left;
    }
    .react-datepicker {
        font-family: IdealSans;
        background-color: #fff;
        font-size: 0.8rem;
        
        color: #000;
        border-radius: 5px;
        display: inline-block;
        position: relative;

        border: 1px solid #470A68;
    }

    .react-datepicker__day--selected{
        background-color: #470A68;
    }

    .react-datepicker__day--today{
        background-color: #470A6830 ;
        border-radius: 5px;
    }
`;

export {
    Container,
    CoverImage,
    ItemWrapper,
    DoubleItemWrapper,
    Wrapper,
    Label,
    FormItem,
    Description,
    Button,
    DatePickerWrapperStyles,
    ImageWrapper
}

