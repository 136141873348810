import { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { Link, useParams } from "react-router-dom"
import Loader from "../../../assets/components/loader/Loader"
import client from "../../../assets/service/client"
import { Container, FormWrapper, Input, Modal, TextWrapper } from "./styles"

const Transfer = () => {
    const { id } = useParams();
    const [receiverEmails, setReceiverEmails] = useState('')
    const [ticketSpecId, setTicketSpecId] = useState('0')

    const getTicketSpecsByEventId = async () => {
        return await client.get(`/event/${id}/ticketSpecs`)
        .then(res => {
            return res.data.ticketSpecs
        })
    }

    const mintTicket = async ({emails, ticketSpecId}) => {
        return await client.post(`/ticket/grant/email`, {
            ticketSpecId,
            emails
        })
        .then(res => {
            return res.data.tickets;
        })
    }
    
    const { data: ticketSpecs } = useQuery('ticketSpecsByEventId', getTicketSpecsByEventId);
    const {isIdle, isLoading, isError, isSuccess, mutate} = useMutation(mintTicket);

    return (
        <Container>
            <TextWrapper>
                <>Quem irá receber o ingresso?</>
            </TextWrapper>
            <FormWrapper>
                <Input value={receiverEmails} onChange={(event) => setReceiverEmails(event.target.value)} placeholder={`Email dos recipientes separado por espaço ou "ENTER" )`} />
                {
                ticketSpecs && 
                <select value={ticketSpecId} onChange={(event) => setTicketSpecId(event.target.value)}>
                    <option value='0'> - </option>
                    {ticketSpecs.map(t => <option key={t.id} value={t.id}>{t.description}</option>)}
                </select>
                }
                {
                isIdle ? <span onClick={() => mutate({emails: receiverEmails, ticketSpecId: parseInt(ticketSpecId)})}>Confirm</span> :
                isLoading ? <Loader scale={0.4} /> :
                isError ? <>algo deu errado</> :
                isSuccess && 
                <>
                    <>Ingresso Enviado com Sucesso</> 
                    <Link to='/'>Voltar</Link>
                </>
                }
            </FormWrapper>
        </Container>
    )
}

export default Transfer